// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-page-js": () => import("/Users/Eric/GitHub/melforprogress.com/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-issue-page-js": () => import("/Users/Eric/GitHub/melforprogress.com/src/templates/issue-page.js" /* webpackChunkName: "component---src-templates-issue-page-js" */),
  "component---src-templates-blog-post-js": () => import("/Users/Eric/GitHub/melforprogress.com/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-resources-page-js": () => import("/Users/Eric/GitHub/melforprogress.com/src/templates/resources-page.js" /* webpackChunkName: "component---src-templates-resources-page-js" */),
  "component---src-templates-index-page-js": () => import("/Users/Eric/GitHub/melforprogress.com/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-press-page-js": () => import("/Users/Eric/GitHub/melforprogress.com/src/templates/press-page.js" /* webpackChunkName: "component---src-templates-press-page-js" */),
  "component---src-templates-vote-page-js": () => import("/Users/Eric/GitHub/melforprogress.com/src/templates/vote-page.js" /* webpackChunkName: "component---src-templates-vote-page-js" */),
  "component---src-templates-sources-page-js": () => import("/Users/Eric/GitHub/melforprogress.com/src/templates/sources-page.js" /* webpackChunkName: "component---src-templates-sources-page-js" */),
  "component---src-templates-callhub-page-js": () => import("/Users/Eric/GitHub/melforprogress.com/src/templates/callhub-page.js" /* webpackChunkName: "component---src-templates-callhub-page-js" */),
  "component---src-templates-tags-js": () => import("/Users/Eric/GitHub/melforprogress.com/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/Users/Eric/GitHub/melforprogress.com/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-tags-index-js": () => import("/Users/Eric/GitHub/melforprogress.com/src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

