module.exports = [{
      plugin: require('/Users/Eric/GitHub/melforprogress.com/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('/Users/Eric/GitHub/melforprogress.com/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/Users/Eric/GitHub/melforprogress.com/src/cms/cms.js"},
    },{
      plugin: require('/Users/Eric/GitHub/melforprogress.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-144378671-1"},
    }]
